<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <AnnouncementForm />
  </main>
</template>

<script>
import AnnouncementForm from '../../../components/admin/announcement/AnnouncementForm.vue';

export default {
  components: { AnnouncementForm },

  metaInfo() {
    return { title: 'Tambah' };
  },

  beforeMount() {
    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/pengumuman', label: 'Pengumuman' },
    ]);
  },
};
</script>
